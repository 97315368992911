<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-account"
          title="Users"
          :value="users"
          sub-icon="mdi-calendar"
          :sub-text="'Last updated: ' + lastRealtimeUpdateFormat"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="red"
          icon="mdi-sofa"
          title="Rooms"
          :value="rooms"
          sub-icon="mdi-calendar"
          :sub-text="'Last updated: ' + lastRealtimeUpdateFormat"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="purple"
          icon="mdi-account-group"
          title="Real rooms"
          :value="realRooms"
          sub-icon="mdi-calendar"
          :sub-text="'Last updated: ' + lastRealtimeUpdateFormat"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-video"
          title="DRTV pageloads last 60 mins"
          :value="drLiveUsers"
          sub-icon="mdi-calendar"
          :smallValue="'(' + drLiveUsersUniq + ')'"
          sub-text="Updates every half hour"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-material-card color="warning" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Realtime users</div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="userHeaders"
              :items="userArr"
              :sort-by="['initTime']"
              :sort-desc="[true]"
              item-key="itemKey"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="6">
        <base-material-card color="warning" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Programs
              <!--<v-switch
                v-model="switchFetchAuto"
                :label="`Fetch automatically: ${switchFetchAuto ? 'on' : 'off'}`"
              ></v-switch>-->
            </div>
          </template>
          <v-card-text>
            <v-btn color="warning" @click="gatherOnetimeStats"
              >Fetch data</v-btn
            >
            <v-btn color="warning" @click="clearDRStats">Clear DR stats</v-btn>
            <v-data-table
              :headers="programHeaders"
              :items="stats.programTitleMap"
              item-key="title"
              :sort-by="['value']"
              :sort-desc="[true]"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mx-auto text-center" color="white" dark>
          <v-card-text>
            <v-sheet color="white">
              <v-sparkline
                :labels="labels"
                :value="stats.roomGraphHourArr"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
              >
                <template v-slot:label="item"> {{ item.value }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="display-1 font-weight-thin">Users per hour</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="primary"
              icon="mdi-sofa"
              title="Rooms"
              :value="stats.numRooms"
              sub-icon="mdi-calendar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="red"
              icon="mdi-account"
              title="Avg users"
              :value="stats.avgNumUsers"
              sub-icon="mdi-calenhdar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="purple"
              icon="mdi-account-group"
              title="Total uniq users"
              :value="stats.totalNumUniqUsers"
              sub-icon="mdi-calendar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="orange"
              icon="mdi-keyboard"
              title="Rooms with messages"
              :value="stats.roomsWithMessages"
              sub-icon="mdi-calendar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="teal"
              icon="mdi-email"
              title="Total messages"
              :value="stats.totalNumMessages"
              sub-icon="mdi-calendar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="amber"
              icon="mdi-video"
              title="Total watch time"
              :value="stats.totalWatchTime"
              sub-icon="mdi-calendar"
              :sub-text="'Last updated: ' + lastDataFetchFormat"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="12">
            <base-material-stats-card
              color="amber"
              icon="mdi-video"
              title="Live rooms"
              :value="liveRooms"
              sub-icon="mdi-calendar"
              :sub-text="
                'Last updated: ' + lastDataFetchFormat + ' - sry for 12 col 😟'
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { auth, database, statsDatabase } from "../../firebase";
import { format } from "timeago.js";
import _ from "lodash";

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  name: "DashboardDashboard",

  data() {
    return {
      auth,
      width: 1,
      radius: 5,
      padding: 5,
      switchFetchAuto: false,
      lineCap: "round",
      gradient: gradients[5],
      labels: [
        "0",
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ],
      gradientDirection: "top",
      gradients,
      fill: false,
      type: "trend",
      autoLineWidth: false,

      userHeaders: [
        {
          sortable: true,
          text: "Room",
          value: "room",
        },
        // {
        //   sortable: false,
        //   text: "ID",
        //   value: "id",
        // },
        {
          sortable: false,
          text: "Name",
          value: "name",
        },

        {
          sortable: true,
          text: "Channel",
          value: "channel",
        },
        {
          sortable: true,
          text: "Time online",
          value: "timeOnline",
        },
        {
          sortable: true,
          text: "Program",
          value: "programTitle",
        },
      ],
      userArr: [],

      programHeaders: [
        {
          sortable: true,
          text: "Program",
          value: "title",
        },
        {
          sortable: false,
          text: "Count",
          value: "value",
        },
      ],

      users: -1,
      rooms: -1,
      realRooms: -1,
      liveRooms: -1,

      lastRealtimeUpdate: 0,
      lastRealtimeUpdateFormat: "Never",
      lastDataFetch: 0,
      lastDataFetchFormat: "Never",

      drLiveUsers: -1,
      drLiveUsersUniq: -1,

      stats: { channelMap: [] },
    };
  },

  mounted: function () {
    // auth
    //   .signInWithEmailAndPassword("admin@sesammen.dk", "vielskerPaolo123")
    //   .then((userCredential) => {
    //     const user = userCredential.user;
    //     console.log("SIGNed in with user", user);
    //     this.realtimeStatistics();
    //   });

    //
    this.realtimeStatistics();
    setInterval(this.updateRealtimeText, 10000); // DISABLED DUE TO DATA USAGE

    /*setInterval(() => {
      if (this.switchFetchAuto) 
       this.gatherOnetimeStats();
    }, 1000 * 60 * 10); // 10 minutes
    this.gatherOnetimeStats();*/
    // setInterval(() => {
    //   if (this.switchFetchAuto) this.gatherDRStats();
    // }, 1000 * 60 * 30); // 30 minutes
    // this.gatherDRStats();
  },

  methods: {
    async gatherDRStats() {
      let before24Hour = new Date().getTime() - 1000 * 60 * 60; // 60 minutes
      const statsSnap = await statsDatabase
        .ref(`stats/`)
        .orderByPriority()
        .startAt(before24Hour)
        .get();

      const val = statsSnap.val();

      this.drLiveUsers = _.keys(val).length;
      this.drLiveUsersUniq = _.chain(val).values().uniqBy("id").value().length;
      // console.log(statsSnap.val());

      // let before24Hour = new Date().getTime() - 24 * 3600 * 1000;
      /*statsDatabase
        .ref()
        .child("stats")
        .orderByPriority()
        .startAt(before24Hour)
        .on("value", function (snap) {
          console.log(_.keys(snap.val()));
        });*/
    },
    async clearDRStats() {
      const drStatsRef = statsDatabase.ref(`stats/`);
      await drStatsRef.remove();
      await this.gatherDRStats();
    },
    async gatherOnetimeStats() {
      const roomsSnap = await database.ref(`rooms/`).get();
      const stats = this.gatherStatistics(roomsSnap);
      this.lastDataFetch = Date.now();
      this.lastDataFetchFormat = format(Date.now());

      this.stats = stats;
      console.log(stats);
    },
    updateRealtimeText() {
      if (this.lastDataFetch == 0) {
        this.lastDataFetchFormat = "Never";
      } else {
        this.lastDataFetchFormat = format(this.lastDataFetch);
      }

      if (this.lastRealtimeUpdate === 0) {
        this.lastRealtimeUpdateFormat = "Never";
      } else {
        this.lastRealtimeUpdateFormat = format(this.lastRealtimeUpdate);
        console.log(this.userArr);
        this.userArr = this.userArr.map((u) => ({
          ...u,
          itemKey: u.id + "" + u.room,
          timeOnline: format(u.initTime),
        }));
      }
    },
    realtimeStatistics() {
      database.ref(`globalConnections/`).on("value", async (snap) => {
        const users = snap.val();

        this.lastRealtimeUpdate = Date.now();

        const rooms = _.keys(_.groupBy(users, "room")).length;
        const realRooms = _.chain(users)
          .groupBy("room")
          .filter((room) => room.length > 1)
          .value();

        const liveRoomsNow = _.sumBy(realRooms, (e) => Number(e[0].isLive), 0);
        console.log(
          new Date().toLocaleTimeString(),
          " users:",
          snap.numChildren(),
          " rooms:",
          rooms,
          " real rooms:",
          realRooms.length,
          " live rooms:",
          liveRoomsNow
        );
        // const programtitle = _.values(e.userhistory)[0].programtitle;

        if (snap.numChildren() === 0) {
          this.users = 0;
          this.rooms = 0;
          this.realRooms = 0;
          this.liveRooms = 0;
          this.userArr = [];
        } else {
          this.users = snap.numChildren();
          this.rooms = rooms;
          this.realRooms = realRooms.length;
          this.liveRooms = liveRoomsNow;

          this.userArr = await Promise.all(
            _.values(users).map(async (u) => {
              const roomSnap = await database
                .ref()
                .child(`rooms`)
                .child(u.room)
                .get();
              console.log(u, roomSnap.val());

              const latestProgram = _.reduce(
                roomSnap.val().programs,
                (acc, elem) => (acc.timestamp > elem.timestamp ? acc : elem)
              );
              console.log("LATEST", latestProgram);

              return {
                ...u,
                channel: latestProgram.isLive,
                programTitle: latestProgram.programTitle,
                timeOnline: format(u.initTime),
              };
            })
          );
        }
      });
    },
    gatherStatistics(snapshot) {
      const rooms = snapshot.val();

      // # of rooms
      const numRooms = _.keys(rooms).length;

      const roomResult = _.map(rooms, (e, k) => {
        // # of messages
        const numMessages = _.keys(e.messages).length;

        const totalMsgLength = _.reduce(
          e.messages,
          (sum, msg) => {
            return sum + msg.content.length;
          },
          0
        );

        const avgMsgLength =
          numMessages === 0 ? 0 : totalMsgLength / numMessages;

        // # of player actions
        const numPlayerPlay = _.reduce(
          e.playerActions,
          (sum, playerAction) => {
            if (playerAction.type === "play") {
              return ++sum;
            }
            return sum;
          },
          0
        );

        const numPlayerPause = _.reduce(
          e.playerActions,
          (sum, playerAction) => {
            if (playerAction.type === "pause") {
              return ++sum;
            }
            return sum;
          },
          0
        );

        const numPlayerSeek = _.reduce(
          e.playerActions,
          (sum, playerAction) => {
            if (playerAction.type === "seek") {
              return ++sum;
            }
            return sum;
          },
          0
        );

        // # of users
        const numUsers = _.keys(e.userhistory).length;

        // # of unique users

        const numUniqUsers = _.keys(
          _.groupBy(e.userhistory, (u) => u.id)
        ).length;

        // userHistory average minutes for user
        let totalWatchTime = _.reduce(
          e.userhistory,
          (sum, user) => {
            const initTime = user.initTime;
            const leaveTime = user.leaveTime ?? Date.now();

            return leaveTime - initTime + sum;
          },
          0
        );

        const isRealRoom = numUniqUsers > 1;

        totalWatchTime = totalWatchTime / 1000 / 60.0;

        const averageWatchTime = totalWatchTime / numUsers;

        // Room created timestamp
        const roomCreatedDate = _.minBy(
          _.values(e.userhistory),
          "initTime"
        ).initTime;
        const roomCreatedHour = new Date(roomCreatedDate).getHours();

        // Room live time (time from first user enter, to last user exit)
        const roomEndedDate =
          _.maxBy(_.values(e.userhistory), "leaveTime")?.leaveTime ?? -1;
        const roomTotalLiveTime =
          roomEndedDate < 0
            ? -1
            : (roomEndedDate - roomCreatedDate) / 1000 / 60;

        // Is room live
        const isLive = _.values(e.userhistory)[0].isLive;

        // Channel (if live)
        const channel = _.values(e.userhistory)[0].channel;

        // Program title
        const programTitle = _.values(e.userhistory)[0].programTitle;

        // Array of browsers
        const browserArr = _.values(e.userhistory).map((e) => e.browser);

        // Array of os
        const osArr = _.values(e.userhistory).map((e) => e.os);

        // Array of user ids
        const userArr = _.values(e.userhistory).map((e) => e.id);

        // Number of active users
        const numActiveUsers = _.keys(e.connections).length;

        return {
          roomID: k,
          numMessages,
          totalMsgLength,
          avgMsgLength,
          numPlayerPlay,
          numPlayerPause,
          numPlayerSeek,
          numUsers,
          numUniqUsers,
          totalWatchTime,
          averageWatchTime,
          // roomCreatedDate,
          roomCreatedHour,
          // roomEndedDate,
          roomTotalLiveTime,
          isLive,
          channel,
          programTitle,
          browserArr,
          osArr,
          userArr,
          numActiveUsers,
          isRealRoom,
        };
      });

      // Total number of real rooms
      const totalNumRealRooms = _.sumBy(roomResult, (e) => e.isRealRoom);

      // Average message length
      const roomsWithMessages = _.chain(roomResult)
        .filter((e) => e.numMessages !== 0)
        .value().length;
      const averageMessageLength =
        _.sumBy(roomResult, "avgMsgLength") / roomsWithMessages;

      const avgPlayerPlay = _.sumBy(roomResult, "numPlayerPlay") / numRooms;
      const avgPlayerPause = _.sumBy(roomResult, "numPlayerPause") / numRooms;
      const avgPlayerSeek = _.sumBy(roomResult, "numPlayerSeek") / numRooms;

      const avgNumUsers = _.sumBy(roomResult, "numUsers") / numRooms;
      const avgNumUniqUsers = _.sumBy(roomResult, "numUniqUsers") / numRooms;
      const totalNumUniqUsers = _.chain(roomResult)
        .map((e) => e.userArr)
        .flatten()
        .uniq()
        .value().length;

      // Contains duplicates
      const totalNumOverallUsers = _.sumBy(roomResult, "numUsers");

      const totalNumUniqRealUsers = _.chain(roomResult)
        .filter((e) => e.isRealRoom)
        .sumBy((e) => e.numUniqUsers)
        .value();

      const avgNumRealUsers = totalNumUniqRealUsers / totalNumRealRooms;

      // Total overall watch time (total user accumulated leavetime - inittime)
      const totalWatchTime = _.sumBy(roomResult, "totalWatchTime") / 60 / 24; // IN days

      // Average time that users are in a room
      const avgWatchTime = _.sumBy(roomResult, "averageWatchTime") / numRooms;

      const roomCreatedHourMap = _.chain(roomResult)
        .groupBy("roomCreatedHour")
        .mapValues((e) => e.length)
        .value();

      const roomGraphHourArr = _.times(24, 0).map(
        (e, i) => roomCreatedHourMap[i] ?? 0
      );

      const avgRoomLiveTime =
        _.sumBy(roomResult, "roomTotalLiveTime") / numRooms;

      const roomIsLiveMap = _.chain(roomResult)
        .groupBy("isLive")
        .mapValues((e) => e.length)
        .mapKeys((__, k) => (k === "true" ? "Live" : "VOD"))
        .value();

      const channelMap = _.chain(roomResult)
        .filter((e) => (e.channel ? e.channel.length > 0 : false))
        .groupBy("channel")
        .mapValues((e) => e.length)
        .value();

      const programTitleMap = _.chain(roomResult)
        .groupBy("programTitle")
        .map((e, k) => ({ title: k, value: e.length }))
        .value();

      const browserMap = _.chain(roomResult)
        .map((e) => e.browserArr)
        .flatMap()
        .groupBy((e) => e)
        .mapValues((e) => e.length)
        .value();

      const osMap = _.chain(roomResult)
        .map((e) => e.osArr)
        .flatMap()
        .groupBy((e) => e)
        .mapValues((e) => e.length)
        .value();

      const totalActiveUsers = _.sumBy(roomResult, "numActiveUsers");

      // Average messages in all REAL rooms
      const averageNumMessages =
        _.chain(roomResult)
          .filter((e) => e.isRealRoom)
          .sumBy("numMessages") / totalNumRealRooms;

      // Total number of messages in ALL rooms
      const totalNumMessages = _.sumBy(roomResult, "numMessages");

      return {
        numRooms,
        averageNumMessages,
        averageMessageLength,
        avgPlayerPlay,
        avgPlayerPause,
        avgPlayerSeek,
        avgRoomLiveTime,
        channelMap,
        programTitleMap,
        browserMap,
        osMap,

        avgNumUsers,
        avgNumUniqUsers,
        totalNumUniqUsers,
        totalWatchTime,
        avgWatchTime,
        roomIsLiveMap,

        roomCreatedHourMap,
        roomsWithMessages,
        totalNumMessages,
        totalActiveUsers,
        roomGraphHourArr,
        totalNumRealRooms,
        totalNumUniqRealUsers,
        avgNumRealUsers,
        totalNumOverallUsers,
      };
    },
  },
};
</script>
